.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.541);
    display: flex;
    z-index: 9998;
    align-items: center;
}
.overlay img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    clear: right;
}

.overlay .detail {
    position: absolute;
    bottom: 10px;
    width: 100%;
    color: #fff;
}

.overlay .dismiss-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 9999;
    cursor: pointer;
}